import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

export default function usernameValidation({ redirect, route, $auth }) {
  const whiteListPage = ['signup-username', 'services-slug', 'nickname', 'signup-role']
  const username = get($auth, 'user.nickname')
  const strategy = get($auth, 'strategy.name')
  if (strategy === 'custom' || strategy === 'local') {
    if ($auth.loggedIn && isEmpty(username) && !whiteListPage.includes(route.name)) {
      return redirect('/signup/username')
    }
    if ($auth.loggedIn && get(username, 'length', 0) < 4 && !whiteListPage.includes(route.name)) {
      return redirect('/signup/username')
    }
    if ($auth.loggedIn && $auth.user && !$auth.user.userType && !whiteListPage.includes(route.name)) {
      return redirect('/signup/role')
    }
  }
  return true
}
