
import { mapState } from 'vuex'
import { get } from 'lodash'
import { showError } from '~/utils'
import Endpoint from '~/repository/endpoint'
import paymentV2 from '~/mixins/payment'

const CloseButton = () => import('~/components/Button/CloseButton.vue')

export default {
  components: {
    CloseButton,
  },
  mixins: [paymentV2],
  data() {
    return {
      // incase we want to use different title between redirecting and opening payment popup
      showRedirectingLabel: false,
      showOpeningLabel: false,
      supportedPromptpayLogo: ['scb-bank.png', 'truemoney-logo.png', 'kosikorn-bank.png', 'ktb-bank.png', 'shopeepay-logo.png'],
    }
  },
  computed: {
    ...mapState({
      transaction: state => state.payment.transactionToCheck,
      promptpayData: state => state.payment.promptpayData,
    }),
    checkStatusInterval: {
      get() {
        return this.$store.state.payment.checkStatusInterval
      },
      set(val) {
        return this.$store.commit('payment/setCheckStatusInterval', val)
      },
    },
  },
  watch: {
    transaction: {
      immediate: true,
      handler(value, oldValue) {
        this.clearStatusInterval()

        if (value && value !== oldValue) {
          this.checkTransaction()

          if ([
            'grabpay',
          ].includes(this.transaction.payment_src.toLowerCase())) {
            this.showRedirectingLabel = true

            setTimeout(() => {
              this.showRedirectingLabel = false
            }, 3000)
          } else if ([
            'stripe_paynow',
          ].includes(this.transaction.payment_src.toLowerCase())) {
            this.showOpeningLabel = true

            setTimeout(() => {
              this.showOpeningLabel = false
            }, 3000)
          }
        } else if (value) {
          // no transaction to check again and remove the loading
          this.closeModal()
        }
      },
    },
  },
  methods: {
    clearStatusInterval() {
      if (this.checkStatusInterval) {
        // TODO: should handle multiple ongoing payment
        clearInterval(this.checkStatusInterval)
        this.checkStatusInterval = null
      }
    },
    async getOrderData() {
      try {
        const { data } = await this.$apiV2.get(`/payment-order/orders/${this.$route.params.id}`)
        this.order = data
      } catch (error) {
        showError(error)
      }
    },
    async getTransactionDetail(transactionID) {
      try {
        if (transactionID.length === 36) {
          return
        }
        const authToken = await this.$auth.strategy.token.get()
        // check transaction id to differentiate recurring and one time transaction
        const url = ((transactionID.startsWith('UT') || transactionID.startsWith('UM')) && !transactionID.startsWith('UTO') && !transactionID.startsWith('UMO'))
          ? `${Endpoint.detailRecurringV2}?sub_human_id=${transactionID}`
          : `${Endpoint.detailTransactionV2}?human_id=${transactionID}`
        const { data } = await this.$axios.get(url,
          {
            baseURL: Endpoint.apiUrl,
            headers: authToken ? { Authorization: authToken } : {},
          })
        this.$store.commit('payment/setTransactionToCheck', data)
      } catch (error) {
        showError(error, 'Get user transactions failed!')
      }
    },
    checkTransaction() {
      // prevent checking for v1 ID to be checked
      if ((this.transaction && this.transaction.human_id && this.transaction.human_id.length === 36) || (this.transaction && this.transaction.sub_human_id && this.transaction.sub_human_id.length === 36)) {
        return undefined
      }
      const donor = this.$cookies.get('success-donor')
      if (this.transaction && this.transaction.payment_status === 'SUCCEED') {
        if (this.$auth.loggedIn && (this.transaction.usecase === 'DIGITAL_GOODS' || this.transaction.usecase === 'MERCHANDISE' || this.transaction.usecase === 'SERVICE')) {
          this.getOrderData()
        }
        return undefined
      }
      return new Promise((resolve, reject) => {
        // Stop previous status checking worker before starting a new one
        if (this.checkStatusInterval) {
          this.clearStatusInterval()
        }

        this.checkStatusInterval = setInterval(
          async () => {
            try {
              if (this.transaction) {
                await this.getTransactionDetail(this.transaction.human_id || this.transaction.sub_human_id)
              }
              const status = get(this.transaction, 'payment_status')
              const subStatus = get(this.transaction, 'active', false)
              if (status === 'SUCCEED') {
                if (donor && this.transaction.usecase === 'DONATION') {
                  this.$cookies.set('success-donation', true)
                }
                if (this.$auth.loggedIn && (this.transaction.usecase === 'DIGITAL_GOODS' || this.transaction.usecase === 'MERCHANDISE' || this.transaction.usecase === 'SERVICE') && this.transaction.payment_status === 'SUCCEED') {
                  this.getOrderData()
                }

                // remove transactionID from cookies
                this.$cookies.remove(this.transaction.human_id)

                this.clearStatusInterval()

                // no transaction to check again and remove the loading
                this.closeModal()
                resolve(status)
              }
              if (status === 'FAILED') {
                this.clearStatusInterval()

                // no transaction to check again and remove the loading
                this.closeModal()
                resolve(status)
              }
              if (subStatus) {
                this.clearStatusInterval()

                // no transaction to check again and remove the loading
                this.closeModal()
                resolve(status)
              }
            } catch (error) {
              this.clearStatusInterval()

              // no transaction to check again and remove the loading
              this.closeModal()
              reject(error)
            }
          },
          3000,
        )

        setTimeout(() => {
          this.clearStatusInterval()

          // no transaction to check again and remove the loading
          this.closeModal()
        }, 600000) // 1000 * 60 * 10 = 10 minutes
      })
    },
    closeModal() {
      this.$store.commit('payment/setPromptpayData', null)
      this.$store.commit('payment/setTransactionToCheck', null)
    },
  },
}
