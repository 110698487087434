
import get from 'lodash/get'
import once from 'lodash/once'
import { mapActions, mapState } from 'vuex'
import { PortalTarget } from 'portal-vue'
import AgoraService from '~/utils/agora'

const SignUpUserModal = () => import('~/components/Modal/SignUpUserModal/index.vue')
const LayoutHeader = () => import('~/components/Layouts/MainHeader.vue')
const LayoutFooter = () => import('~/components/Layouts/LayoutFooterNew.vue')
const IncommingCallModal = () => import('~/components/Modal/Chat/IncommingCall.vue')
const Voicebar = () => import('~/containers/Chats/voicebar.vue')
const AnnouncementBanner = () => import('~/components/AnnouncementBanner/index.vue')
const PaymentLoading = () => import('~/components/PaymentLoading/index.vue')
const DownloadApp = () => import('~/components/DownloadApp/DownloadApp.vue')

export default {
  components: {
    LayoutHeader,
    LayoutFooter,
    SignUpUserModal,
    IncommingCallModal,
    Voicebar,
    PortalTarget,
    AnnouncementBanner,
    PaymentLoading,
    DownloadApp,
  },
  data() {
    return {
      showPopup: false,
      attempt: 1,
      supportedCountry: ['SG', 'ID', 'MY', 'TH', 'PH'],
      // list country default language base
      defaultLangCountry: ['TW', 'ID', 'TH', 'VN'],
    }
  },
  computed: {
    userId() {
      return get(this.$auth, 'user.id') || ''
    },
    userRoles() {
      return get(this.$auth, 'user.userType') || ''
    },
    ...mapState({
      countryUser: state => state.settings.countryUser,
      authStrategy: state => state.auth.strategy,
      call: state => state.chat.call,
    }),
    isLoggedIn() {
      return get(this.$auth, 'loggedIn')
    },
    downloadAppPopupVisibility() {
      const list = ['index', 'nickname']
      return list.includes(this.$route.name) && this.$auth.loggedIn
    },
  },
  watch: {
    // Watch the route to update the body class when the route changes
    '$route.name': {
      immediate: true,
      handler(newVal) {
        this.updateBodyClass(newVal)
      },
    },
    // Add a watcher for auth state
    isLoggedIn(newValue) {
      if (newValue) {
        // Re-initialize Qualaroo when user logs in
        this.initializeQualaroo()
      } else {
        // Reset to unknown when user logs out
        // eslint-disable-next-line no-underscore-dangle
        window._kiq = window._kiq || []
        // eslint-disable-next-line no-underscore-dangle
        window._kiq.push(['identify', 'unknown'])
      }
    },
  },
  async mounted() {
    if (typeof window.Stripe === 'undefined') {
      await this.importStripe()
      this.attempt += 1
    }

    if (this.countryUser === '') {
      if (!this.$auth.loggedIn) {
        await this.getCountryUser()
        if (this.defaultLangCountry.includes(this.countryUser)) {
          const lang = this.countryUser === 'VN' ? 'vi' : this.countryUser.toLowerCase()
          this.$store.commit('settings/setLocale', lang)
          this.$dayjs.locale(lang)
          this.$i18n.setLocale(lang)
        }
      } else {
        this.$store.commit('settings/setCountryUser', get(this.$auth, 'user.profile.countryCode'))
      }
    }

    if (this.$auth.loggedIn && this.userId) {
      this.$gtag.config({ user_id: this.userId })
      this.$gtm.push({ event: 'userIdSet', user_id: this.userId, roles: this.userRoles })
      this.$store.dispatch('profile/getUserFollowing', this.userId)
      this.$store.dispatch('profile/getUserSubscribtions', this.userId)
      this.getUserSummary(this.userId)
    }
    this.getTiers()

    const isNewUser = this.$auth.$storage.getUniversal('isNewUser')
    if (this.$auth.loggedIn) {
      if (isNewUser) {
        this.showPopup = true
        this.$auth.$storage.removeUniversal('isNewUser')
      }
    }

    if (this.$auth.loggedIn && this.$auth.user && this.$auth.user.verified) {
      once(() => {
        this.initializeChat()
        const agoraService = new AgoraService(this)
        agoraService.initialize()
      })()
    }

    // Qualaroo
    if (window !== undefined) {
      this.$nextTick(() => {
        this.initializeQualaroo()
      })
    }
  },
  methods: {
    ...mapActions({
      getCountryUser: 'settings/getCountryUser',
      getTiers: 'settings/getTiers',
      getUserSummary: 'profile/getUserSummary',
      initializeChat: 'chat/initializeChat',
    }),
    initializeQualaroo() {
      // eslint-disable-next-line no-underscore-dangle
      window._kiq = window._kiq || []

      setTimeout(() => {
        if (this.$auth.loggedIn && this.userId) {
          // eslint-disable-next-line no-underscore-dangle
          window._kiq.push(['identify', get(this.$auth, 'user.email') || get(this.$auth, 'user.nickname') || this.userId])

          // eslint-disable-next-line no-underscore-dangle
          window._kiq.push(['set', {
            id: this.userId,
            nickname: get(this.$auth, 'user.nickname') || '',
            email: get(this.$auth, 'user.email') || '',
            verified: get(this.$auth, 'user.verified') || false,
            tier: get(this.$auth, 'user.tier') || 'Free',
            type: get(this.$auth, 'user.userType') || '',
          }])
        } else {
          // eslint-disable-next-line no-underscore-dangle
          window._kiq.push(['identify', 'unknown'])
        }
        // eslint-disable-next-line no-underscore-dangle
        console.log('window._kiq', window._kiq)
      }, 2000) // Adjust the timeout duration as needed
    },
    isShowDark() {
      if (this.$route.name !== 'nickname') return true
      if (this.$route.name === 'nickname' && this.$colorMode.preference === 'dark') return true
      return false
    },
    importStripe() {
      // stop if already attempted 30 times
      if (this.attempt === 30) {
        const error = new Error('Stripe could not be loaded')
        this.$sentry.captureException(error)
        return
      }

      // every 10 attempts, reimport script
      if (this.attempt % 10 === 0) {
        const script = document.createElement('script')
        script.src = 'https://js.stripe.com/v3'
        script.hid = 'stripe_js'
        script.defer = true

        document.head.appendChild(script)
      }

      // if Stripe not loaded yet, re-execute this function
      if (typeof window.Stripe === 'undefined') {
        setTimeout(this.importStripe, 500)
        this.attempt += 1
      }
    },
    handleClose() {
      this.showPopup = false
    },
    updateBodyClass(routeName) {
      let pageName = routeName
      if (process.client) {
        if (routeName === 'index') {
          if (this.$auth.loggedIn) {
            pageName = 'dashboard'
          } else {
            pageName = 'home'
          }
        }
        // Ensure this runs only on the client side
        document.body.className = ''
        document.body.classList.add(`page-${pageName}`)
      }
    },
  },
}
