import { showError } from '~/utils'

export default {
  async fetchPosts(_, params) {
    const filter = {
      author: this.$auth.user.id,
      searchKeyword: params.searchKeyword,
      page: params.page,
      perPage: params.per_page,
      postAccessTypes: params.postAccessTypes,
      userMembershipId: params.userMembershipId,
      scheduling: params.scheduling,
      orderBy: params.orderBy,
    }
    const cleanedFilter = Object.entries(filter).reduce((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = value
      }
      return acc
    }, {})

    let posts

    // eslint-disable-next-line no-unused-vars
    const paramQuery = new URLSearchParams(cleanedFilter).toString()
    try {
      const response = await this.$axios.get(`/posts?${paramQuery}`)

      posts = {
        items: response?.data?.data || [],
        total: response?.data?.metadata?.total || 0,
      }
    } catch (error) {
      showError(error)
    }
    return posts
  },
}
